import './App.css';
import { Routes, Route } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import { AddGym, Exraids, Footer, Layout, Logedin, Login, RequireAuth, Sidebar } from './components';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

function App() {
	
	const { auth } = useAuth();
	console.log(auth);

	return (
		<>
		<Sidebar />
		<div id="wrapper">
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="/" element={!auth?.trainerid ? <Login /> : <Logedin />} />
					<Route path="login" element={!auth?.trainerid ? <Login /> : <Logedin />} />
					<Route path="exraids" element={<Exraids />} />
					<Route element={<RequireAuth />}>
						<Route path="addgym" element={<AddGym />} />					
					</Route>
				</Route>
			</Routes>	
		</div>
		<Footer />
		</>
  );
}

export default App;