import React, { Component } from "react";

class Footer extends Component {
    render(){
        return(
            <footer id="footer" className="wrapper style1-alt">
            <div className="inner">
                <ul className="menu">
                    <li>&copy; Untitled. All rights reserved.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                </ul>
            </div>
            </footer>
        )
    }
}
export default Footer