import useAuth from '../hooks/useAuth';

export default function Logedin() {
    
    const containerClass = 'wrapper style1 fullscreen'
    
    const { auth, setAuth } = useAuth();
    const errMsg = ""; 
        
    const handleLogout = async (e) => {
        e.preventDefault();
        setAuth();
    }
    
    return (
        <section id="login" className={containerClass}>
            <div className="inner">
                <h2>als {auth?.trainername} angemeldet</h2>
                <p>deine Statistik f&uuml;r Arenaorden</p>                
                <div className="style1">
                   
                            <div className="fields verticalcenter">
                                    <ul className="actions">
                                            <li><button className="button submit" id="submitbutton" onClick={handleLogout}>Logout</button></li>
                                    </ul>
                                    <p className="error" aria-live="assertive">{errMsg}</p>
                            </div>    
                </div>
            </div>
        </section>
    )
}