import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import md5 from 'md5';
const LOGIN_URL = '/login/web';

export default function Login() {
    
    const containerClass = 'wrapper style1 fullscreen'
    
    const { setAuth } = useAuth();
   
    const userRef = useRef();
    const errRef = useRef();
    
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])
    

    const [showPassword, setShowPassword] = useState(false);

    function handleShowPasswordChange() {
        showPassword ? setShowPassword(false) : setShowPassword(true);
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const auth = {
                username : user,
                password : md5(pwd)
            }
            const response = await axios.post(LOGIN_URL, {}, {auth, headers: { 'Content-Type': 'application/json'}});
            const data = JSON.stringify(response?.data?.data).substring(1).slice(0,-1);            
            const token = JSON.parse(data).token;
            const trainername = JSON.parse(data).trainername;
            const trainerid = JSON.parse(data)[0]?.kontoID;            
            setAuth({ token, trainername, trainerid })            
            setErrMsg('Login');         
        } catch(err) {
            let errorMsg = err?.response?.data?.errmessage
            errorMsg = !errorMsg ? 'Login nicht erfolgreich' : errorMsg
            if(!err?.response){
                setErrMsg('keine Antwort vom Server');
            } else {
                setErrMsg(errorMsg)
            }
        }
       // setUser('')
       // setPwd('')        
    }
    
    return (
        <section id="login" className={containerClass}>
            <div className="inner">
                <h2>LogIn</h2>
                <p>melde dich mit deinen Zugangsdaten f&uuml;r www.exraids.de an</p>                
                <div className="split style1">
                    <form onSubmit={handleLogin}>
                           <div className="fields">
                                <div className="field half">
                                    <label htmlFor="user">Benutzername</label>
                                    <input 
                                        type="text" 
                                        id="user"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}                                        
                                    />
                                </div>
                                <div className="field half">
                                    <label htmlFor="password">Passwort</label>
                                    <input 
                                        type={showPassword ? "text" : "password"}
                                        id="password"                                         
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}                                        
                                    />                                    
                                    <i className="far fa-eye" id="togglePassword" style={{position: 'relative', marginTop: '-33px', marginRight: '-200px', cursor: 'pointer'}} onClick={handleShowPasswordChange}></i>
                                </div> 
                            </div>
                            <div className="fields verticalcenter">
                                    <ul className="actions">
                                            <li><button className="button submit" id="submitbutton">Login</button></li>
                                    </ul>
                                    <p ref={errRef} className="error" aria-live="assertive">{errMsg}</p>
                            </div>                            
                    </form>                    
                </div>
            </div>
        </section>
    )
}