import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import axios from '../api/axios';
const ADDGYM_URL = '/gyms/addgymbypmb';

export default function AddGym() {

    // const cstyle = this.props.cstyle || 'style1'
    // const ctype = this.props.ctype || 'fullscreen'
    // const cfade = this.props.cfade || ''
    // const containerClass = 'wrapper '+cstyle+' '+ctype+' '+cfade
    const containerClass = 'wrapper style1 fullscreen'

    const [pmbs, setPmbs] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const { auth } = useContext(AuthContext);

    const errRef = useRef();

    //console.log(auth);
	

    useEffect(() => {
        setErrMsg('');
    }, [pmbs])

    const handleAddGym = async (e) => {
        e.preventDefault();
        try {
            if(auth?.trainerid === undefined) {
                setErrMsg('bitte erst anmelden');
            }else{
                const body = {pmbstring: pmbs} 
                const headers = { 'Authorization': 'Bearer ' + auth?.token, 'Content-Type': 'multipart/form-data'}; 
                const response = await axios.post(ADDGYM_URL + '?userid=' + auth?.trainerid, body, {headers});
               
                setErrMsg(response?.data?.data);
                console.log(response?.data?.data);
                //console.log(data?.data);
            }                 
        } catch(err) {
            if(!err?.response){
                setErrMsg('keine Antwort vom Server');
            } else if (err.response?.status === 403){
                setErrMsg('Zugangsdaten nicht korrekt');
            } else {
                setErrMsg('Login nicht erfolgreich');
            }
        }
    }

    return (
        <section id="addgym" className={containerClass}>
            <div className="inner">
                <h2>Arena hinzuf&uuml;gen</h2>
                <p>neue Arena hinzuf&uuml;gen aus einem Portal Map Bot Suchergebnis</p>
                <div className="split style1">
                    <form onSubmit={handleAddGym}>
                        <div className="fields">
                            <div className="field half">
                                <label htmlFor="pmbs">Portal Map Bot String</label>
                                <textarea rows="9" style={{width: '500px'}} id="pmbs" onChange={(e) => setPmbs(e.target.value)} value={pmbs} />
                            </div>
                        </div>
                        <div className="fields verticalcenter">                            
                            <ul className="actions">
                                <li><button className="button submit">hinzuf&uuml;gen</button></li>
                            </ul>                                                                                
                            <p ref={errRef} className="error small align-left" aria-live="assertive">{errMsg}</p>
                        </div>
                    </form>
                </div>
            </div>
        </section>        
    )
    }