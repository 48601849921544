import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export default function Sidebar() {

	const { auth } = useAuth();
	
	return(
		<section id="sidebar">
			<div className="inner">
				<nav>
					<ul>
						<li><Link to="/login"><i className="fa fa-user fa-lg"></i> {!auth?.trainername ? 'Login' : auth?.trainername}</Link></li>
						<li><Link to="/addgym"><i className="fa fa-plus-circle fa-lg"></i> Arena hinzuf&uuml;gen</Link></li>
						<li><Link to="/exraids"><i className="fa fa-map fa-lg"></i> Karte</Link></li>						
						
					</ul>
				</nav>
			</div>			
		</section>
	)
}